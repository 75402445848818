<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/directionalTask' }">指派任务</el-breadcrumb-item>
          <el-breadcrumb-item>任务详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <!-- 基本信息 -->
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="任务ID：">{{ form.specificTaskCode }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="来源：" prop="">
                <span v-if="form.source == 0">新创建</span>
                <span v-else-if="form.source == 1">巡查</span>
                <span v-else-if="form.source == 2">故障</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="任务名称：" prop="">{{ form.specificTaskName }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="任务描述：" prop="">{{ form.taskDescribe }}</el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="外勤人员：">
                {{ form.staffName }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="截止日期：" prop="date">
                {{ dayjs(form.endDate).format('YYYY-MM-DD') }}
              </el-form-item>
            </el-col>
          </el-row>
          
          <!-- 水表配置 -->
          <div class="form-title">水表信息</div>
          <div class="table-list" v-if="selectPatrolData.length>0">
            <el-table :data="selectPatrolData" style="width: 100%">
              <el-table-column prop="userCode" label="用户编号"></el-table-column>
              <el-table-column prop="waterMeterCode" label="水表编号"></el-table-column>
              <el-table-column prop="userName" label="用户名称"></el-table-column>
              <el-table-column prop="address" label="水表地址"></el-table-column>
              <el-table-column prop="useProperty" label="用水性质"></el-table-column>
              <el-table-column prop="originPlace" label="水表产地"></el-table-column>
              <el-table-column prop="type" label="水表类型"></el-table-column>
              <el-table-column prop="caliber" label="水表口径"></el-table-column>
              <el-table-column prop="model" label="水表型号"></el-table-column>
              <el-table-column prop="valve" label="阀门状态"></el-table-column>
              <el-table-column prop="readType" label="抄表方式"></el-table-column>
              <el-table-column prop="capacity" label="水表容量"></el-table-column>
              <el-table-column prop="watchCase" label="表壳号"></el-table-column>
              <el-table-column prop="coreNum" label="表芯号"></el-table-column>
              <el-table-column prop="runningStatus" label="水表状态"></el-table-column>
              <el-table-column prop="position" label="安装位置"></el-table-column>
              <el-table-column prop="createTime" label="安装时间">
                <template slot-scope="scope">
                  {{ scope.row.createTime ? dayjs(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="form-title" style="margin-top:30px;display:flex;align-items:center;">
            <div>处理结果</div>
            <div style="margin-left:20px;">
              <el-tag type="warning" size="small" v-show="form.result==0">未解决</el-tag>
              <el-tag type="success" size="small" v-show="form.result==1">已解决</el-tag>
              <el-tag type="danger" size="small" v-show="form.result==2">误报</el-tag>
            </div>
          </div>
          <!-- <div style="margin: 0 0 5px 15px;font-size:14px;">处理说明：</div>
          <div style="border:1px solid #EBEEF5;padding:20px;margin-bottom:20px;font-size:14px;">
            无内容
          </div> -->
          
          <div class="img-box">
            <el-image 
              class="images"
              v-for="item in form.fileUrls"
              :key="item"
              :src="item"
              :preview-src-list="[item]">
            </el-image>
            <!-- <img v-for="item in form.deviceArchivesImages" :key="item.id" :src="item.url" /> -->
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="处理人员：">{{form.staffName?form.staffName:'（无）'}}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="处理时间：">
                  {{ form.reportTime?dayjs(form.reportTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}
              </el-form-item>
            </el-col>
          </el-row>

          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/directionalTask')">取消</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import layTime from '@/components/layTime'
export default {
  components: {
    layTime
  },
  data() {
    return {
      loading: false,
      row: {},
      form: {},
      rules: {},
      selectPatrolData: [],
      staffInfos: [],
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d < n;
        }
      },
    }
  },
  methods: {
    dayjs,
    dateSecond(str) {
      let strArr = str.split(':')
      let second =  (strArr[0] * 3600) + (strArr[1] * 60) + Number(strArr[2])
      return second
    },
    loadWaterCodeData(waterMeterCode) {
      this.tableLoading = true
      this.$ajax.post('meterQuery', {
        pageNum: 0,
        pageSize: 0,
        waterMeterCode: waterMeterCode,
      }).then(res => {
        this.selectPatrolData = res.data
        this.tableLoading = false
      }).catch(err=>{
        this.tableLoading = false
      })
    },
    loadDetail() {
      this.loading = true
      this.$ajax.post('specificDetail',{}, {
        specificTaskCode: this.row.specificTaskCode
      }).then(res => {
        this.form = res.data
        this.loadWaterCodeData(res.data.waterMeterCode)
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push("/directionalTask");
      return;
    }
    this.row = this.$route.params.row;
    this.loadDetail()
  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.config-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.staffBox {
  width: 325px;
  background: #fff;
  margin-right: 20px;
  padding: 4px;
  min-height: 40px;
  border: 1px solid #DCDFE6;
  border-radius: 5px;
//   box-shadow: 0px 0px 10px rgba(211, 211, 211, 0.5);
}

.drawer-bottom {
  position: static;
  z-index: 1;
  text-align: right;
  padding: 10px 20px 10px 0;
}
.img-box {
  .images {
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    margin: 0 8px 8px 0;
    display: inline-block;
  }
}
</style>
<style lang="less">
.el-drawer.rtl {
  overflow: scroll;
}
</style>